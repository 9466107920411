import { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Icon, Spinner } from "@/widgets";
import { Error } from "@/widgets/forms";
import { getError } from "@/utils";
import { useToast } from "@/hooks";
import { SessionsApi } from "@/apis";
import { ISession } from "@/models";
import { setAuthCookies } from "@/utils/auth";
import useSchema from "./hooks/useSchema";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import UserUnavailableModal from "@/widgets/UserBlockedModal";
import * as S from "./styles";
import { useCards } from "@/store/features/cards";
import { queryClient } from "@/libs/react-query";

export default function LoginForm() {
  const { t } = useTranslation();
  const router = useRouter();
  const [loginFailMsg, setLoginFailMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { refetch } = useCards();
  const [showPassword, setShowPassword] = useState(false);

  const schema = useSchema();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const toastInvalidLogin = () => {
    useToast(
      t("loginForm.invalidCredentials"),
      <p>{t("loginForm.confirmYourCredentials")}</p>
    );
  };

  const onSubmitHandler = async (data: any) => {
    setLoginFailMsg("");
    setLoading(true);

    try {
      const response = (await SessionsApi.getSession(
        data.username,
        data.password
      )) as any;
      if (response?.accessTokenValue) {
        const data = response as ISession;
        setAuthCookies(data);
        setLoading(false);
        refetch();
        queryClient.invalidateQueries();
        router.push("/");
      } else {
        setLoading(false);
        setLoginFailMsg("loginForm.invalidLoginOrPassword"),
          toastInvalidLogin();
      }
    } catch (error: any) {
      if (error?.status === 403) {
        const isUserBlocked = error?.errors[0]?.code === "USER_BLOCKED";
        setIsBlocked(isUserBlocked);
        setIsOpen(true);
      } else {
        setLoginFailMsg("loginForm.invalidLoginOrPassword");
        toastInvalidLogin();
      }
      setLoading(false);
    }
  };

  const callSupport = () => router.push("/help-center/contact");

  useEffect(() => {
    if (loginFailMsg) {
      setLoginFailMsg("loginForm.invalidLoginOrPassword");
    }
  }, [t]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <S.ContentAuthLogin>
      <S.TitleWelcome>{t("loginForm.welcome")}</S.TitleWelcome>

      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <S.FormItem>
          <label>{t("loginForm.login")}</label>

          <input
            placeholder={t("loginForm.loginPlaceholder")}
            id="username"
            {...register("username")}
          />
          <Error element="span" message={getError(errors, "username")} />
        </S.FormItem>

        <S.FormItem>
          <S.PasswordLabel>
            {t("loginForm.passwordPlaceholder")}
          </S.PasswordLabel>
          <S.PasswordContainer>
            <S.PasswordInput
              type={showPassword ? "text" : "password"}
              placeholder={t("loginForm.passwordPlaceholder")}
              id="password"
              {...register("password")}
            />
            <S.PasswordButton type="button" onClick={togglePasswordVisibility}>
              <Icon icon={showPassword ? "newEye" : "newCloseEye"} />
            </S.PasswordButton>
          </S.PasswordContainer>
          <Error element="span" message={getError(errors, "password")} />
        </S.FormItem>

        <S.ForgotRow className={styles.forgotRow}>
          <Link href="/auth/recover-password" id="forgotPassword">
            <span>{t("loginForm.forgotPassword")}</span>
          </Link>
        </S.ForgotRow>
        <S.ActionRow>
          {loading ? (
            <Spinner size="small" className={styles.childWithPadding} />
          ) : (
            <button type="submit" disabled={!isValid}>
              {t("loginForm.loginButton")}
            </button>
          )}
        </S.ActionRow>
        {loginFailMsg && <Error message={t(loginFailMsg)} />}
        <S.Register
          id="createRegister"
          onClick={() => router.push("/auth/sign-up")}
        >
          {t("loginForm.titleRegister")}
        </S.Register>
      </form>
      <UserUnavailableModal
        isOpen={isOpen}
        isBlocked={isBlocked}
        closed={() => setIsOpen(false)}
        contactSupport={() => callSupport()}
      />
    </S.ContentAuthLogin>
  );
}
