import styled from "styled-components";

export const ContentAuth = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  @media (max-width: 670px) {
    margin-top: 60px;
  }
`;

export const Background = styled.div`
  position: fixed;
  right: 0;
  margin-top: -296px;
  z-index: -1;
  top: 50%;

  @media (max-width: 670px) {
    display: none;
  }
`;

export const ContentText = styled.div`
  width: calc(100% - 420px);
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    width: 90%;
  }
`;

export const ContentDownload = styled.div`
  width: 100%;

  h4 {
    font-size: 16px;
    color: #7d89b0;
    font-weight: 400;
    margin: 60px 0 15px;

    @media (max-width: 670px) {
      margin: 20px 0 15px;
    }

    @media (max-width: 1100px) {
      margin: 20px 0 15px;
    }
  }
`;

export const Download = styled.div`
  width: 80%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 670px) {
    margin-bottom: 20px;
  }

  @media (max-width: 1100px) {
    margin-bottom: 40px;
  }
`;

export const Button = styled.div`
  width: calc(50% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 8px;
  background-color: #ebebff;
  box-shadow: 0px 6px 13.35px -6.68px #b6b6ff26;
  color: #413fcf;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  img {
    margin-right: 5px;
  }
`;

export const Auth = styled.div`
  width: 390px;
  padding: 20px 0;
  margin-top: 40px;
  border: 1px solid #ebebff;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 6.68px 10.01px -3.34px #10182808;
  box-shadow: 0px 20.03px 26.7px -6.68px #10182814;
  position: relative;
  background-color: #fff;

  @media (max-width: 767px) {
    width: 90%;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const ContentSlider = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const Title = styled.h4`
  font-size: 24px;
  font-weight: 600;
  color: #30374f;
`;
export const SubTitle = styled.h5`
  font-size: 24px;
  color: #4d4dff;
`;

export const TextAuth = styled.p`
  width: 90%;
  font-size: 18px;
  color: #5d6b98;
  margin-top: 20px;
`;
