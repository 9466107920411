import { routeGuardForLoggedUser } from "@/utils/auth";
import { AuthLayout } from "@/pages/auth/components";
import { LoginForm } from "./forms";

export const getServerSideProps = (context: any) => {
  return routeGuardForLoggedUser(context, () => {
    return {
      props: {
        layout: "unregistered",
      },
    };
  });
};

export default function LoginPage() {
  return (
    <AuthLayout>
      <LoginForm />
    </AuthLayout>
  );
}
