import styled from "styled-components";

export const ContentAuthLogin = styled.div`
  padding: 20px;
  /* border: 1px solid #ebebff;
  border-radius: 6px;
  box-shadow: 0px 6.68px 10.01px -3.34px #10182808;
  box-shadow: 0px 20.03px 26.7px -6.68px #10182814; */
  position: relative;
  background-color: #fff;
`;

export const Blur = styled.div`
  position: absolute;
  top: -200px;
  right: -300px;
`;

export const TitleWelcome = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: #30374f;
  padding: 5px 0 10px;
`;

export const Entry = styled.h3``;
export const FormItem = styled.div`
  margin-bottom: 20px;

  label {
    margin-bottom: 10px;
    font-size: 12px;
    color: #30374f;
    font-weight: 500;
  }

  input {
    border: 1px solid #dcdfea;
  }
`;
export const ForgotRow = styled.div`
  margin: -10px 0 20px 0;
  text-align: right;
  font-size: 14px;
  font-weight: 500;

  span {
    border-bottom: 1px solid #413fcf;
  }
`;
export const ActionRow = styled.div`
  margin-bottom: 10px !important;
  text-align: center;
  cursor: pointer;

  button {
    width: 100%;
    font-family: Karla;
    margin: 0 auto;
    padding: 10px 0;
    border-radius: 8px;
    background-color: #4553fd;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const Register = styled.div`
  font-size: 14px;
  color: #413fcf;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
  text-decoration: underline;
  cursor: pointer;
`;

export const PasswordContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfea;
  border-radius: 8px;

  &:focus-within {
    outline: 2px solid var(--primary-5);
  }
`;

export const PasswordLabel = styled.label``;

export const PasswordInput = styled.input`
  border: none !important;
  &:focus {
    outline: none !important;
  }
`;

export const PasswordButton = styled.button`
  background: none;
  cursor: pointer;
  position: absolute;
  left: 88%;
`;
