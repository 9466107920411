import { Button, Modal } from "@/widgets";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  isOpen: boolean;
  closed: () => void;
  contactSupport: () => void;
  isBlocked?: boolean;
}

const UserUnavailableModal = (props: IProps) => {
  const { t } = useTranslation();
  const { isOpen, closed, contactSupport, isBlocked } = props;

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        closed();
      }}
      showCloseIcon={true}
    >
      <div className={styles.container}>
        <>
          <div className={styles.title}>
            <img src="/images/icons/info-circle-blue.svg" alt="" color="red" />

            {isBlocked ? (
              <h2>{t("userBlockedModal.title")}</h2>
            ) : (
              <h2>{t("userBlockedModal.titleDeleted")}</h2>
            )}
          </div>

          <div className={styles.text}>
            <p>{t("userBlockedModal.text")}</p>
          </div>

          <div className={styles.contentButton}>
            <Button size="medium" variant="default" onClick={contactSupport}>
              {t("userBlockedModal.button")}
            </Button>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default UserUnavailableModal;
