import { useI18n } from "@/hooks";
import yup from "@/validators/validator";
import content from "./useSchema.json";

export default function useSchema() {
  const { i18n } = useI18n(content);

  return yup.object().shape({
    username: yup.string().required(i18n("required")),
    password: yup.string().required(i18n("required")),
  });
}
